.sidebarContainer {
  width: 170px;
  background: #313a46;
  height: 100%;
  z-index: 1;
  position: fixed;
  top: 50px;
  padding-top: 20px;
}

.navigationButton {
  margin-bottom: 1px;
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  align-items: center;
  height: 40px;
  width: 100%;
  color: darkgrey;
}

.navigationButton:hover {
  background: rgba(41,121,236,1);
  cursor: pointer;
  color:white;
}

.navigationButtonLabel {
  margin-left: 10px;
}

.navigationContainer{
  flex-direction: column;
  display: flex;
  align-items: center;
}

.navigationActive {
  background: rgba(41,121,236,0.8);
  color: white;
}

.navigationActive:hover {
  background: rgba(41,121,236,1);
}
