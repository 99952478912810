.portalContainer {
  margin-top: 50px;
  background: rgba(50,50,50,1);
}

.appCardsContainer {
  color: black;
  display: flex;
  flex-direction: row;
}

.appCard {
  margin-right: 10px;
}

.appDescription {
  height: 50px;
}
