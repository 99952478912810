.loginContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #3A3A3A;
  margin-top: 40px;
}

.loginContent {
  padding: 40px !important;
  width: 100%;
  max-width: 500px;
  padding-top: 0px !important;
  color: black;
  margin: 10px;
  margin-top: 20px;
  height: 180px;
  background: white !important;
}

.loginHeaderLogo {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 15px;
  height: 50px;
}

.loginHeaderLogo img {
  height: 50px;
  width: auto;
}

.plastkastareLogo {
  height: 100px;
  width: auto;
  background: white;
}

.guestLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nameField {
  max-width: 300px;
  width: 100%;
}

.loginOptions {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginOptions h2 {
  margin-bottom: 15px;
}

.microsoftLoginLogo {
  width: 240px;
}

.microsoftLogin {
}

.microsoftLogin:hover {
  cursor: pointer;
}

.guestLoginButton {
  height: 45px;
  width: 240px;
  border: 1px solid grey;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  background: #17a2b8;
  color: white;
}

.guestLoginButton:hover {
  border: 1px solid lightblue;
  cursor: pointer;
  background: #138496;
}
